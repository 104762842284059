const messageBox = require('./messageBox');

/**
 * Caixa de mensagem para retornos de erros.
 * Retorna também o status code do erro.
 *
 * @param statusCode
 */
function errorMessageBox(statusCode) {
  if (statusCode !== 422) {
    const title = 'Ops! :(';
    const text = `Ocorreu um erro em sua requisição, nós já fomos notificados, 
          por favor tente novamente mais tarde. (Cod: ${statusCode})`;

    messageBox(title, text, 'error', true, false, true);
  }
}

module.exports = errorMessageBox;
