import { isValidCnpj, isValidCep } from '@brazilian-utils/validators';

window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');
require('bootstrap');
require('jquery-mask-plugin');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min');
require('block-ui');
require('trumbowyg');
require('trumbowyg/dist/langs/pt_br');
require('bootstrap-select');
require('chart.js');

const messageBox = require('../modules/messageBox');
const formValidator = require('../modules/formValidator');
const deletionDialogBox = require('../modules/deletionDialogBox');
const errorMessageBox = require('../modules/errorMessageBox');
// const validationMessageBox = require('../modules/validationMessageBox');

$(function() {
  // Global Settings

  // Ações a serem executadas em todas requisições ajax
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
  });

  // Tooltip default
  $('[data-toggle="tooltip"]').tooltip();

  // Popover default
  $('[data-toggle="popover"]').popover();
  //
  // Bootstrap Select class.
  $.fn.selectpicker.Constructor.BootstrapVersion = '4';
  $.fn.selectpicker.Constructor.DEFAULTS.multipleSeparator = ' | ';

  $('.selectpicker').selectpicker({
    actionsBox: true,
    selectOnTab: true,
    deselectAllText: 'Limpar',
    noneSelectedText: 'Selecione',
    selectAllText: 'Selec.Tudo',
    noneResultsText: 'Nada encontrado',
    iconBase: 'fas',
    tickIcon: 'fa-check',
    selectedTextFormat: 'count',
    countSelectedText(numSelected) {
      return (numSelected === 1) ? '{0} selecionado' : '{0} selecionados';
    },
  });

  // Trumbowyg

  // app.
  $.trumbowyg.svgPath = '/assets/webfonts/app/trumbowyg/icons.svg';

  // Datepicker

  // BlockUI
  $.blockUI.defaults.message = '<i class="fas fa-circle-notch fa-spin"></i> Processando...';
  $.blockUI.defaults.css.fontSize = '20px';
  $.blockUI.defaults.css.padding = '5px';
  $.blockUI.defaults.css.border = 'none';
  $.blockUI.defaults.css.backgroundColor = '#575fcf';
  $.blockUI.defaults.css.color = '#fff';
  $.blockUI.defaults.fadeOut = 200;

  // // Slider
  // const slider = tns({
  //   container: '.js-slider',
  //   items: 3,
  //   slideBy: 'page',
  //   autoplay: true,
  // });

  // End Global Settings

  // Auth Suite

  // Register
  const authRegisterForm = $('.auth-register #auth-register-form');
  formValidator(authRegisterForm);

  // Login
  const authLoginForm = $('.auth-login #auth-login-form');
  formValidator(authLoginForm);

  // Recovery
  const authRecoveryForm = $('.auth-recovery #auth-recovery-form');
  formValidator(authRecoveryForm);

  // Reset
  const authResetForm = $('.auth-reset #auth-reset-form');
  formValidator(authResetForm);

  // End Auth Suite

  // Start

  const startPageSelector = '.start';
  const startForm = $(startPageSelector).find('#start-form');
  formValidator(startForm);

  // Preenche os campos através do CEP.
  startForm.find('.zip-code-input').on('keyup', function() {
    const input = $(this);
    const form = startForm;
    const zipCode = input.val().replace(/[-. ]/gi, '');

    if (isValidCep(zipCode)) {
      // Sendo válido no cliente, desativa o input CEP.
      input.prop('disabled', true);
      // bloqueia a UI.
      $.blockUI();

      // Roda a requisição ajax com timeout.
      setTimeout(function() {
        $.ajax({
          dataType: 'jsonp',
          url: `https://viacep.com.br/ws/${zipCode}/json/`,
        }).done(function(response) {
          if (!response.erro) {
            // Preenche os elementos com os dados da response.

            // Logradouro.
            form.find('.public-area-input').val(response.logradouro);

            // Número.
            form.find('.number-input').trigger('focus');

            // Bairro.
            form.find('.neighborhood-input').val(response.bairro);

            // Cidade.
            form.find('.city-input').val(response.localidade);

            // UF.
            form.find('.fu-input').val(response.uf);

            // Reativa CEP.
            input.prop('disabled', false);

            // Reativa a UI.
            $.unblockUI();
          } else {
            // Em caso de falha nos dados enviados.

            // Reativa a UI.
            $.unblockUI();

            // Responde ao usuário.
            const message = 'CEP não encontrado, por favor preencha manualmente.';
            messageBox('Erro', message, 'error', true, false, false);

            // Reativa CEP.
            input.prop('disabled', false);
          }
        }).fail(function() {
          // Em caso de falha na request.

          // Reativa a UI.
          $.unblockUI();

          // Responde ao usuário.
          const message = `Não conseguimos processar sua solicitação.
            Preencha os campos manualmente.`;
          messageBox('Erro', message, 'error', true, false, false);

          // Reativa CEP.
          input.prop('disabled', false);
        });
      }, 2000);
    }
  });

  // Mostra/Oculta itens de PF/PJ conforme seleção de radio.
  startForm.find('[name=type]').on('click', function() {
    const form = startForm;
    const inputValue = $(this).val();
    const hashClass = form.hasClass('was-validated');

    // Remove a classe de validação do BS4, caso possua.
    if (hashClass) {
      form.removeClass('was-validated');
    }

    if (inputValue === 'N') {
      // Desativa itens de PJ.
      form.find('.le-type-container').addClass('d-none');

      // CNPJ
      form.find('.ctr-input').prop('required', false).val('');

      // Razão Social.
      form.find('.company-name-input').prop('required', false).val('');

      // Nome Fantasia.
      form.find('.trading-name-input').val('');

      // Mostra itens de PF
      form.find('.np-type-container').removeClass('d-none');

      // CPF
      form.find('.itin-input').prop('required', true).val('');

      // Nome
      form.find('.first-name-input').prop('required', true).val('');

      // Sobrenome
      form.find('.last-name-input').prop('required', true).val('');
    } else {
      // Desativa itens de PF.
      form.find('.np-type-container').addClass('d-none');

      // CPF
      form.find('.itin-input').prop('required', false).val('');

      // Nome
      form.find('.first-name-input').prop('required', false).val('');

      // Sobrenome
      form.find('.last-name-input').prop('required', false).val('');

      // Reativa itens de PJ.
      form.find('.le-type-container').removeClass('d-none');

      // CNPJ
      form.find('.ctr-input').prop('required', true).val('');

      // Razão Social.
      form.find('.company-name-input').prop('required', true).val('');

      // Nome Fantasia.
      form.find('.trading-name-input').val('');
    }
  });

  // Mascaras de Input.

  // CNPJ
  startForm.find('.ctr-input').mask('00.000.000/0000-00', {
    reverse: true,
  });

  // CPF
  startForm.find('.itin-input').mask('000.000.000-00', {
    reverse: true,
  });

  // CEP
  startForm.find('.zip-code-input').mask('00.000-000');

  // End Start

  // Dashboard
  // ...

  // Customers

  // index

  // Atribui ações nos links/buttons da tabela.
  const customersPageSelector = '.customers';

  // Delete button em index.
  $(customersPageSelector).
    find('.js-btn-customers-destroy').
    on('click', function(event) {
      event.preventDefault();
      const customerId = $(this).attr('data-customer-id');
      const method = 'DELETE';
      const url = `/panel/customers/${customerId}`;
      const request = {
        method,
        url,
        success(response) {
          if (response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  // create

  const customersCreatePageSelector = '.customers-create';
  const customersCreateForm = $(customersCreatePageSelector).
    find('#customers-create-form');
  formValidator(customersCreateForm);

  // Máscaras dos inputs.

  // CNPJ
  customersCreateForm.find('.ctr-input').mask('00.000.000/0000-00', {
    reverse: true,
  });

  // CPF
  customersCreateForm.find('.itin-input').mask('000.000.000-00', {
    reverse: true,
  });

  // CEP
  customersCreateForm.find('.zip-code-input').mask('00.000-000');

  // Aniversário
  customersCreateForm.find('.birthday-input').mask('00/00/0000');

  // Datepicker.
  customersCreateForm.find('.birthday-input').datepicker({
    todayBtn: true,
    todayHighlight: true,
    autoclose: true,
    format: 'dd/mm/yyyy',
    language: 'pt-BR',
  });

  // Telefone Celular
  customersCreateForm.find('.mobile-phone-input').mask('(00) 00000-0000');

  // Torna visível/invisível os campos de PJ/PF conforme a seleção.
  customersCreateForm.find('.js-type-input').on('change', function() {
    const form = customersCreateForm;
    const inputValue = $(this).val();
    const hashClass = form.hasClass('was-validated');

    // Remove a classe de validação do BS4, caso possua.
    if (hashClass) {
      form.removeClass('was-validated');
    }

    // Caso o valor do input seja do tipo (N) pessoa física(Natural Person).
    if (inputValue === 'N') {
      // Remove os elementos de pessoa jurídica(Padrão no carregamento).

      // Container de Pessoa Jurídica.
      form.find('.le-type-container').addClass('d-none');

      // Razão Social Input.
      form.find('.company-name-input').
        prop('required', false).
        prop('disabled', true).
        val('');

      // Nome Fantasia Input.
      form.find('.trading-name-input').prop('disabled', true).val('');

      // CNPJ Input.
      form.find('.ctr-input').
        prop('required', false).
        prop('disabled', true).
        val('');

      // Adiciona e valida os elementos de pessoa física.

      // Container de Pessoa Física.
      form.find('.np-type-container').removeClass('d-none');

      // CPF Input.
      form.find('.itin-input').
        prop('disabled', false).
        prop('required', true).
        val('').
        trigger('focus');
    } else {
      // Remove os elementos de pessoa física.

      // Container de pessoa física.
      form.find('.np-type-container').addClass('d-none');

      // CPF Input.
      form.find('.itin-input').
        prop('required', false).
        prop('disabled', true).
        val('');

      // Adiciona e valida os elementos de pessoa jurídica.

      // Container de pessoa jurídica.
      form.find('.le-type-container').removeClass('d-none');

      // Razão Social Input.
      form.find('.company-name-input').
        prop('disabled', false).
        prop('required', true).
        val('');

      // Nome Fantasia Input.
      form.find('.trading-name-input').prop('disabled', false).val('');

      // CNPJ Input.
      form.find('.ctr-input').
        prop('disabled', false).
        prop('required', true).
        val('');
    }
  });

  // Busca os dados da empresa pelo CNPJ na API.
  customersCreateForm.find('.ctr-input').on('keyup', function() {
    const input = $(this);
    const form = customersCreateForm;
    const numbers = input.val().replace(/[/\-. ]/gi, '');

    if (numbers.length === 14) {
      if (isValidCnpj(numbers)) {
        // Sendo válido no cliente, desativa o input CNPJ.
        input.prop('disabled', true);

        // bloqueia a UI.
        $.blockUI();

        // Roda a requisição ajax com timeout.
        setTimeout(function() {
          $.ajax({
            dataType: 'jsonp',
            url: `https://www.receitaws.com.br/v1/cnpj/${numbers}`,
          }).done(function(response) {
            if (response.status !== 'ERROR') {

              // Preenche os elementos com os dados da response e os desativa,
              // quando os mesmos tenham conteúdo.

              // Razão social.
              if (response.nome) {
                form.find('.company-name-input').
                  val(response.nome).
                  prop('disabled', true);
              }

              // Nome fantasia.
              if (response.fantasia) {
                form.find('.trading-name-input').
                  val(response.fantasia).
                  prop('disabled', true);
              }

              // CEP.
              if (response.cep) {
                form.find('.zip-code-input').
                  val(response.cep).
                  prop('disabled', true);
              }

              // Logradouro.
              if (response.logradouro) {
                form.find('.public-area-input').
                  val(response.logradouro).
                  prop('disabled', true);
              }

              // Número.
              if (response.numero) {
                form.find('.number-input').
                  val(response.numero).
                  prop('disabled', true);
              }

              // Complemento.
              if (response.complemento) {
                form.find('.complement-input').
                  val(response.complemento).
                  prop('disabled', true);
              }

              // Bairro.
              if (response.bairro) {
                form.find('.neighborhood-input').
                  val(response.bairro).
                  prop('disabled', true);
              }

              // Cidade/Município/Localidade.
              if (response.municipio) {
                form.find('.city-input').
                  val(response.municipio).
                  prop('disabled', true);
              }

              // Unidade Federativa.
              if (response.uf) {
                form.find('.fu-input').val(response.uf).prop('disabled', true);
              }

              // Tipo Pessoa.
              form.find('.js-type-input').prop('disabled', true);

              // Botão de Reset.
              form.find('.js-btn-cleanup').prop('disabled', false);

              // Rótulo de endereço.
              form.find('.address-label-input').trigger('focus');

              // Reativa a UI.
              $.unblockUI();
            } else {
              // Em caso de falha nos dados enviados.

              // Reativa a UI.
              $.unblockUI();

              // Responde ao usuário.

              // Possíveis respostas:
              // 1) CNPJ rejeitado pela Receita Federal.
              // 2) CNPJ inválido.

              const message = `${response.message}.
              Preencha os campos manualmente.`;
              messageBox('Erro', message, 'error', true, false, false);

              // Reativa o input CNPJ preservando o value
              // para preenchimento manual.
              input.prop('disabled', false);
            }
          }).fail(function() {
            // Em caso de falha na request.

            // Reativa a UI.
            $.unblockUI();

            // Responde ao usuário.
            const message = `Não conseguimos processar sua solicitação.
            Preencha os campos manualmente.`;
            messageBox('Erro', message, 'error', true, false, false);

            // Reativa o input CNPJ preservando o value
            // para preenchimento manual.
            input.prop('disabled', false);
          });
        }, 2000);
      } else {
        // Resposta de validação no cliente.
        messageBox('Erro', ' CNPJ Inválido', 'error', true, false, false);

        // Limpa o input CNPJ.
        input.val('');
      }
    }
  });

  // Preenche os campos através do CEP.
  customersCreateForm.find('.zip-code-input').on('keyup', function() {
    const input = $(this);
    const form = customersCreateForm;
    const zipCode = input.val().replace(/[-. ]/gi, '');

    if (isValidCep(zipCode)) {
      // Sendo válido no cliente, desativa o input CEP.
      input.prop('disabled', true);

      // bloqueia a UI.
      $.blockUI();

      // Roda a requisição ajax com timeout.
      setTimeout(function() {
        $.ajax({
          dataType: 'jsonp',
          url: `https://viacep.com.br/ws/${zipCode}/json/`,
        }).done(function(response) {
          if (!response.erro) {
            // Preenche os elementos com os dados da response.

            // Logradouro.
            form.find('.public-area-input').
              val(response.logradouro).
              prop('disabled', true);

            // Bairro.
            form.find('.neighborhood-input').
              val(response.bairro).
              prop('disabled', true);

            // Cidade.
            form.find('.city-input').
              val(response.localidade).
              prop('disabled', true);

            // UF.
            form.find('.fu-input').val(response.uf).prop('disabled', true);

            // Número.
            form.find('.number-input').trigger('focus');

            // Botão de Reset.
            form.find('.js-btn-cleanup').prop('disabled', false);

            // Reativa a UI.
            $.unblockUI();
          } else {
            // Em caso de falha nos dados enviados.

            // Reativa a UI.
            $.unblockUI();

            // Responde ao usuário.
            const message = 'CEP não encontrado, por favor preencha manualmente.';
            messageBox('Erro', message, 'error', true, false, false);

            // Reativa o input CEP preservando o value
            // para preenchimento manual.
            input.prop('disabled', false);
          }
        }).fail(function() {
          // Em caso de falha na request.

          // Reativa a UI.
          $.unblockUI();

          // Responde ao usuário.
          const message = `Não conseguimos processar sua solicitação.
            Preencha os campos manualmente.`;
          messageBox('Erro', message, 'error', true, false, false);

          // Reativa o input CEP preservando o value
          // para preenchimento manual.
          input.prop('disabled', false);
        });
      }, 2000);
    }
  });

  // Reseta os campos do formulário.
  customersCreateForm.find('.js-btn-cleanup').on('click', function(event) {
    event.preventDefault();
    const form = customersCreateForm;

    // Reseta todos os elementos que podem ser desabilitados no form pelo retorno das APIs.

    // Botão de Reset.
    $(this).prop('disabled', true);

    // Tipo Pessoa.
    form.find('.js-type-input').prop('disabled', false);

    // CNPJ.
    form.find('.ctr-input').prop('disabled', false).val('');

    // Razão social.
    form.find('.company-name-input').prop('disabled', false).val('');

    // Nome Fantasia.
    form.find('.trading-name-input').prop('disabled', false).val('');

    // CEP.
    form.find('.zip-code-input').prop('disabled', false).val('');

    // Logradouro.
    form.find('.public-area-input').prop('disabled', false).val('');

    // Número.
    form.find('.number-input').prop('disabled', false).val('');

    // Complemento.
    form.find('.complement-input').prop('disabled', false).val('');

    // Bairro.
    form.find('.neighborhood-input').prop('disabled', false).val('');

    // Cidade.
    form.find('.city-input').prop('disabled', false).val('');

    // UF.
    form.find('.fu-input').prop('disabled', false).val('');
  });

  // Reativa os inputs no submit do form.
  customersCreateForm.on('submit', function(event) {
    const form = $(this);
    if (form[0].checkValidity()) {
      form.find('.js-type-input').prop('disabled', false);
      form.find('.ctr-input').prop('disabled', false);
      form.find('.company-name-input').prop('disabled', false);
      form.find('.trading-name-input').prop('disabled', false);
      form.find('.zip-code-input').prop('disabled', false);
      form.find('.public-area-input').prop('disabled', false);
      form.find('.number-input').prop('disabled', false);
      form.find('.complement-input').prop('disabled', false);
      form.find('.neighborhood-input').prop('disabled', false);
      form.find('.city-input').prop('disabled', false);
      form.find('.fu-input').prop('disabled', false);
    } else {
      event.preventDefault();
    }
  });

  // Edit

  const customersEditPageSelector = '.customers-edit';
  const customersEditForm = $(customersEditPageSelector).
    find('#customers-edit-form');
  formValidator(customersEditForm);

  // Máscaras dos inputs.

  // CNPJ
  customersEditForm.find('.ctr-input').mask('00.000.000/0000-00', {
    reverse: true,
  });

  // CPF
  customersEditForm.find('.itin-input').mask('000.000.000-00', {
    reverse: true,
  });

  // Aniversário
  customersEditForm.find('.birthday-input').mask('00/00/0000');

  // Datepicker.
  customersEditForm.find('.birthday-input').datepicker({
    todayBtn: true,
    todayHighlight: true,
    autoclose: true,
    format: 'dd/mm/yyyy',
    language: 'pt-BR',
  });

  // Customer Addresses.

  // index

  // Delete button em index.
  const customersAddressesPageSelector = '.customers-addresses';

  // Delete
  $(customersAddressesPageSelector).
    find('.js-btn-customers-addresses-destroy').
    on('click',
      function(event) {
        event.preventDefault();
        const customerId = $(this).attr('data-customer-id');
        const addressId = $(this).attr('data-address-id');
        const method = 'DELETE';
        const url = `/panel/customers/${customerId}/addresses/${addressId}`;
        const request = {
          method,
          url,
          success(response) {
            if (response.invalidRequest) {
              messageBox('Erro', response.invalidRequest, 'error', true, false,
                false);
            } else if (response.successResponse) {
              window.location.reload();
            }
          },
          error(response) {
            errorMessageBox(response.status);
          },
        };
        deletionDialogBox(request);
      });

  // create

  const customersAddressesCreatePageSelector = '.customers-addresses-create';
  const customersAddressesCreateForm = $(customersAddressesCreatePageSelector).
    find('#customers-addresses-create-form');
  formValidator(customersAddressesCreateForm);

  // Máscaras dos inputs.

  // CEP
  customersAddressesCreateForm.find('.zip-code-input').mask('00.000-000');

  // Preenche os campos através do CEP.
  customersAddressesCreateForm.find('.zip-code-input').on('keyup', function() {
    const input = $(this);
    const form = customersAddressesCreateForm;
    const zipCode = input.val().replace(/[-. ]/gi, '');

    if (isValidCep(zipCode)) {
      // Sendo válido no cliente, desativa o input CEP.
      input.prop('disabled', true);

      // bloqueia a UI.
      $.blockUI();

      // Roda a requisição ajax com timeout.
      setTimeout(function() {
        $.ajax({
          dataType: 'jsonp',
          url: `https://viacep.com.br/ws/${zipCode}/json/`,
        }).done(function(response) {
          if (!response.erro) {
            // Preenche os elementos com os dados da response.

            // Logradouro.
            form.find('.public-area-input').
              val(response.logradouro).
              prop('disabled', true);

            // Bairro.
            form.find('.neighborhood-input').
              val(response.bairro).
              prop('disabled', true);

            // Cidade.
            form.find('.city-input').
              val(response.localidade).
              prop('disabled', true);

            // UF.
            form.find('.fu-input').val(response.uf).prop('disabled', true);

            // Número.
            form.find('.number-input').trigger('focus');

            // Botão de Reset.
            form.find('.js-btn-cleanup').prop('disabled', false);

            // Reativa a UI.
            $.unblockUI();
          } else {
            // Em caso de falha nos dados enviados.

            // Reativa a UI.
            $.unblockUI();

            // Responde ao usuário.
            const message = 'CEP não encontrado, por favor preencha manualmente.';
            messageBox('Erro', message, 'error', true, false, false);

            // Reativa o input CEP preservando o value
            // para preenchimento manual.
            input.prop('disabled', false);
          }
        }).fail(function() {
          // Em caso de falha na request.

          // Reativa a UI.
          $.unblockUI();

          // Responde ao usuário.
          const message = `Não conseguimos processar sua solicitação.
            Preencha os campos manualmente.`;
          messageBox('Erro', message, 'error', true, false, false);

          // Reativa o input CEP preservando o value
          // para preenchimento manual.
          input.prop('disabled', false);
        });
      }, 2000);
    }
  });

  // Reseta os campos do formulário.
  customersAddressesCreateForm.find('.js-btn-cleanup').
    on('click', function(event) {
      event.preventDefault();
      const form = customersAddressesCreateForm;

      // Reseta todos os elementos desabilitados do form pelo retorno da API.

      // Botão de Reset.
      $(this).prop('disabled', true);

      // CEP.
      form.find('.zip-code-input').
        prop('disabled', false).
        val('').
        trigger('focus');

      // Logradouro.
      form.find('.public-area-input').prop('disabled', false).val('');

      // Número.
      form.find('.number-input').prop('disabled', false).val('');

      // Complemento.
      form.find('.complement-input').prop('disabled', false).val('');

      // Bairro.
      form.find('.neighborhood-input').prop('disabled', false).val('');

      // Cidade.
      form.find('.city-input').prop('disabled', false).val('');

      // UF.
      form.find('.fu-input').prop('disabled', false).val('');
    });

  // Reativa os inputs no submit do form.
  customersAddressesCreateForm.on('submit', function(event) {
    const form = $(this);
    if (form[0].checkValidity()) {
      form.find('.zip-code-input').prop('disabled', false);
      form.find('.public-area-input').prop('disabled', false);
      form.find('.number-input').prop('disabled', false);
      form.find('.complement-input').prop('disabled', false);
      form.find('.neighborhood-input').prop('disabled', false);
      form.find('.city-input').prop('disabled', false);
      form.find('.fu-input').prop('disabled', false);
    } else {
      event.preventDefault();
    }
  });

  // edit

  const customersAddressesEditPageSelector = '.customers-addresses-edit';
  const customersAddressesEditForm = $(customersAddressesEditPageSelector).
    find('#customers-addresses-edit-form');
  formValidator(customersAddressesEditForm);

  // Máscaras dos inputs.

  // CEP
  customersAddressesEditForm.find('.zip-code-input').mask('00.000-000');

  // Customers Contacts

  // index

  // Delete button em index.
  const customersContactsPageSelector = '.customers-contacts';

  // Delete
  $(customersContactsPageSelector).
    find('.js-btn-customers-contacts-destroy').
    on('click',
      function(event) {
        event.preventDefault();
        const customerId = $(this).attr('data-customer-id');
        const contactId = $(this).attr('data-contact-id');
        const method = 'DELETE';
        const url = `/panel/customers/${customerId}/contacts/${contactId}`;
        const request = {
          method,
          url,
          success(response) {
            if (response.invalidRequest) {
              messageBox('Erro', response.invalidRequest, 'error', true, false,
                false);
            } else if (response.successResponse) {
              window.location.reload();
            }
          },
          error(response) {
            errorMessageBox(response.status);
          },
        };
        deletionDialogBox(request);
      });

  // create

  const customersContactsCreatePageSelector = '.customers-contacts-create';
  const customersContactsCreateForm = $(customersContactsCreatePageSelector).
    find('#customers-contacts-create-form');
  formValidator(customersContactsCreateForm);

  // Máscaras dos inputs.

  // Mobile Phone
  customersContactsCreateForm.find('.mobile-phone-input').
    mask('(00) 00000-0000');

  // edit

  const customersContactsEditPageSelector = '.customers-contacts-edit';
  const customersContactsEditForm = $(customersContactsEditPageSelector).
    find('#customers-contacts-edit-form');
  formValidator(customersContactsEditForm);

  // Máscaras dos inputs.

  // Mobile Phone
  customersContactsEditForm.find('.mobile-phone-input').mask('(00) 00000-0000');

  // Groups

  // index

  // Delete button em index.
  const groupsPageSelector = '.groups';

  // Delete
  $(groupsPageSelector).find('.js-btn-groups-destroy').on('click',
    function(event) {
      event.preventDefault();
      const groupId = $(this).attr('data-group-id');
      const method = 'DELETE';
      const url = `/panel/groups/${groupId}`;
      const request = {
        method,
        url,
        success(response) {
          if (response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  // create

  const groupsCreatePageSelector = '.groups-create';
  const groupsCreateForm = $(groupsCreatePageSelector).
    find('#groups-create-form');
  formValidator(groupsCreateForm);

  // edit

  const groupsEditPageSelector = '.groups-edit';
  const groupsEditForm = $(groupsEditPageSelector).find('#groups-edit-form');
  formValidator(groupsEditForm);

  //

  // Suppresion

  const suppressionPageSelector = '.suppression';

  // Delete button em index

  // Delete
  $(suppressionPageSelector).find('.js-btn-suppression-destroy').on('click',
    function(event) {
      event.preventDefault();
      const suppressionId = $(this).attr('data-suppression-id');
      const method = 'DELETE';
      const url = `/panel/suppressions/${suppressionId}`;
      const request = {
        method,
        url,
        data: { suppression_id: suppressionId },
        success(response) {
          if (!response.successResponse && response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  //

  // Messages

  // index

  const messagesPageSelector = '.messages';

  // Delete button em index

  // Delete
  $(messagesPageSelector).find('.js-btn-messages-destroy').on('click',
    function(event) {
      event.preventDefault();
      const messageId = $(this).attr('data-message-id');
      const method = 'DELETE';
      const url = `/panel/messages/${messageId}`;
      const request = {
        method,
        url,
        data: { message_id: messageId },
        success(response) {
          if (!response.successResponse && response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  // create

  const messagesCreatePageSelector = '.messages-create';
  const messagesCreateForm = $(messagesCreatePageSelector).
    find('#messages-create-form');
  formValidator(messagesCreateForm);

  // Bloqueia Caso Group e Contact estejam vazios.
  messagesCreateForm.on('submit', function(e) {
    const groups = messagesCreateForm.find('.group-input').selectpicker('val');
    const contacts = messagesCreateForm.find('.contact-input').
      selectpicker('val');
    if (!groups.length && !contacts.length) {
      const message = 'Escolha um destinatário.';
      e.preventDefault();
      messageBox('Erro', message, 'error', true, false, false);

      messagesCreateForm.find('.submit-container > .js-btn-submit').
        prop('disabled', false).
        removeClass('d-none');

      messagesCreateForm.find('.submit-container > .loading').
        addClass('d-none');
    }
  });

  // Editor
  messagesCreateForm.find('#content-input').trumbowyg({
    lang: 'pt_br',
    resetCss: true,
    // removeformatPasted: true,
    tagsToRemove: ['script', 'link'],
    imageWidthModalEdit: true,
  });

  // Mostra/Oculta os inputs relacionados a uma mensagem agendada.
  messagesCreateForm.find('[name=send_type]').on('click', function() {
    const form = messagesCreateForm;
    const input = $(this).val();
    const hashClass = form.hasClass('was-validated');

    // Remove a classe de validação do BS4, caso possua.
    if (hashClass) {
      form.removeClass('was-validated');
    }

    // Padrão de entrada de carregamento da página é Agendada.

    // Caso o input seja do tipo (2) = 'now'.
    if (input === '2') {
      // Desativa os elementos requeridos/disponiveis em uma mensagem agendada.

      // day.
      form.find('.day-input').
        prop('required', false).
        prop('disabled', true).
        val('');

      // month.
      form.find('.month-input').
        prop('required', false).
        prop('disabled', true).
        val('');

      // year.
      form.find('.year-input').
        prop('required', false).
        prop('disabled', true).
        val('');

      // revalidate.
      form.find('.revalidate-input').
        prop('disabled', true).
        prop('checked', false);

      // user.
      form.find('.user-input').
        prop('disabled', true).
        prop('required', false).
        val('').
        selectpicker('refresh');

      // interval
      form.find('.notify-between-input').
        prop('disabled', true).
        prop('required', false).
        val('');

      // status.
      form.find('.status-input').prop('disabled', true).prop('checked', true);

      form.find('.js-btn-submit').
        html('<i class="fas fa-check"></i> <strong>Enviar</strong>');
    } else {
      // Reativa os elementos requeridos/disponiveis em uma mensagem agendada.

      // day.
      form.find('.day-input').
        prop('required', true).
        prop('disabled', false).
        val('');

      // month.
      form.find('.month-input').
        prop('required', true).
        prop('disabled', false).
        val('');

      // year.
      form.find('.year-input').
        prop('required', true).
        prop('disabled', false).
        val('');

      // revalidate.
      form.find('.revalidate-input').prop('disabled', false);

      // status.
      form.find('.status-input').prop('checked', false).prop('disabled', false);

      form.find('.js-btn-submit').
        html('<i class="fas fa-check"></i> <strong>Agendar</strong>');
    }
  });

  // Ativa/desativa e valida combo de usuários caso revalidar esteja marcado/desmarcado.
  messagesCreateForm.find('.js-revalidate-input').on('change', function() {
    const form = messagesCreateForm;
    const input = $(this).is(':checked');
    if (input) {
      // user
      form.find('.user-input').
        prop('required', true).
        prop('disabled', false).
        val('').
        selectpicker('refresh');
      // interval.
      form.find('.notify-between-input').
        prop('required', true).
        prop('disabled', false).
        val('');
    } else {
      // user
      form.find('.user-input').
        prop('required', false).
        prop('disabled', true).
        val('').
        selectpicker('refresh');
      // interval.
      form.find('.notify-between-input').
        prop('required', false).
        prop('disabled', true).
        val('');
    }
  });

  // show

  // edit

  const messagesEditPageSelector = '.messages-edit';
  const messagesEditForm = $(messagesEditPageSelector).
    find('#messages-edit-form');
  formValidator(messagesEditForm);

  // Bloqueia Caso Group e Contact estejam vazios.
  messagesEditForm.on('submit', function(e) {
    const groups = messagesEditForm.find('.group-input').selectpicker('val');
    const contacts = messagesEditForm.find('.contact-input').
      selectpicker('val');
    if (!groups.length && !contacts.length) {
      const message = 'Escolha um destinatário.';
      e.preventDefault();
      messageBox('Erro', message, 'error', true, false, false);

      messagesEditForm.find('.submit-container > .js-btn-submit').
        prop('disabled', false).
        removeClass('d-none');

      messagesEditForm.find('.submit-container > .loading').addClass('d-none');
    }
  });

  // Editor
  messagesEditForm.find('#content-input').trumbowyg({
    lang: 'pt_br',
    resetCss: true,
    // removeformatPasted: true,
    tagsToRemove: ['script', 'link'],
    imageWidthModalEdit: true,
  });

  // Ativa/desativa combo de usuários caso revalidar esteja marcado/desmarcado.
  messagesEditForm.find('.js-revalidate-input').on('change', function() {
    const form = messagesEditForm;
    const input = $(this).is(':checked');
    if (input) {
      // user
      form.find('.user-input').
        prop('required', true).
        prop('disabled', false).
        val('').
        selectpicker('refresh');
      // interval.
      form.find('.notify-between-input').
        prop('required', true).
        prop('disabled', false).
        val('');
    } else {
      // user
      form.find('.user-input').
        prop('required', false).
        prop('disabled', true).
        val('').
        selectpicker('refresh');
      // interval.
      form.find('.notify-between-input').
        prop('required', false).
        prop('disabled', true).
        val('');
    }
  });

  // Remove o anexo...
  $(messagesEditPageSelector).find('.js-btn-attachment-delete').on('click',
    function(event) {
      event.preventDefault();
      const messageId = $(this).attr('data-message-id');
      const method = 'DELETE';
      const url = `/panel/messages/${messageId}/attachment-delete`;
      const request = {
        method,
        url,
        data: { message_id: messageId },
        success(response) {
          if (!response.successResponse && response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  // Aprova a message.
  $(messagesPageSelector).find('.js-btn-approval').on('click',
    function(event) {
      event.preventDefault();
      const messageId = $(this).attr('data-message-id');
      const method = 'PUT';
      const url = `/panel/messages/${messageId}/approval`;
      const request = {
        method,
        url,
        success(response) {
          if (response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      swal({
        title: 'Confirme a Aprovação',
        text: 'Você realmente deseja aprovar este recurso ?',
        icon: 'warning',
        buttons: {
          confirm: {
            text: 'Aprovar',
            value: true,
            visible: true,
            className: 'btn btn-success',
            closeModal: true,
          },
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
            className: 'btn btn-primary',
            closeModal: true,
          },
        },
      }).then((confirm) => {
        if (confirm) {
          $.ajax(request);
        }
      });
    });

  // Clona a Mensagem
  $(messagesPageSelector).find('.js-btn-clone').on('click', function(event) {
    event.preventDefault();
    const messageId = $(this).attr('data-message-id');
    const method = 'POST';
    const url = `/panel/messages/${messageId}/clone`;
    const request = {
      method,
      url,
      success(response) {
        if (response.invalidRequest) {
          messageBox('Erro', response.invalidRequest, 'error', true, false,
            false);
        } else if (response.successResponse) {
          messageBox('Sucesso', 'Clonado com Sucesso', 'success', true, false,
            true);
        }
      },
      error(response) {
        errorMessageBox(response.status);
      },
    };
    swal({
      title: 'Confirme a ação',
      text: 'Você realmente deseja clonar este recurso ?',
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'Clonar',
          value: true,
          visible: true,
          className: 'btn btn-success',
          closeModal: true,
        },
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true,
        },
      },
    }).then((confirm) => {
      if (confirm) {
        $.ajax(request);
      }
    });
  });

  // Dispatches

  // Departments

  // index

  const departmentsPageSelector = '.departments';

  // Delete button em index

  // Delete

  $(departmentsPageSelector).find('.js-btn-departments-destroy').on('click',
    function(event) {
      event.preventDefault();
      const departmentId = $(this).attr('data-department-id');
      const method = 'DELETE';
      const url = `/panel/departments/${departmentId}`;
      const request = {
        method,
        url,
        success(response) {
          if (!response.successResponse && response.employees) {
            const employees = 'Existem funcionários associados a este departamento.';
            messageBox('Erro', employees, 'error', true, false, false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  // create

  const departmentsCreatePageSelector = '.departments-create';
  const departmentsCreateForm = $(departmentsCreatePageSelector).
    find('#departments-create-form');
  formValidator(departmentsCreateForm);

  // show

  // edit

  const departmentsEditPageSelector = '.departments-edit';
  const departmentsEditForm = $(departmentsEditPageSelector).
    find('#departments-edit-form');
  formValidator(departmentsEditForm);

  // Employees

  // index

  const employeesPageSelector = '.employees';

  // Delete button em index

  // Delete
  $(employeesPageSelector).find('.js-btn-employees-destroy').on('click',
    function(event) {
      event.preventDefault();
      const employeeId = $(this).attr('data-employee-id');
      const method = 'DELETE';
      const url = `/panel/employees/${employeeId}`;
      const request = {
        method,
        url,
        success(response) {
          // console.log(`Deletou o funcionário id ${employeeId}`);
          // console.log(response);
        },
        error(response) {
          // console.log(response);
        },
      };
      deletionDialogBox(request);
    });

  // create

  const employeesCreatePageSelector = '.employees-create';
  const employeesCreateForm = $(employeesCreatePageSelector).
    find('#employees-create-form');
  formValidator(employeesCreateForm);

  // Datepicker.
  employeesCreateForm.find('.birthday-input').datepicker({
    todayBtn: true,
    todayHighlight: true,
    autoclose: true,
    format: 'dd/mm/yyyy',
    language: 'pt-BR',
  });

  // Máscaras

  // Telefone Celular
  employeesCreateForm.find('.mobile-phone-input').mask('(99) 999999999');

  // show

  // edit

  const employeesEditPageSelector = '.employees-edit';
  const employeesEditForm = $(employeesEditPageSelector).
    find('#employees-edit-form');
  formValidator(employeesEditForm);

  // Datepicker.
  employeesEditForm.find('.birthday-input').datepicker({
    todayBtn: true,
    todayHighlight: true,
    autoclose: true,
    format: 'dd/mm/yyyy',
    language: 'pt-BR',
  });

  // Máscaras

  // Telefone Celular
  employeesEditForm.find('.mobile-phone-input').mask('(99) 999999999');

  // Users

  // index

  const usersPageSelector = '.users';

  // Delete button em index

  // Delete
  $(usersPageSelector).find('.js-btn-users-destroy').on('click',
    function(event) {
      event.preventDefault();
      const userId = $(this).attr('data-user-id');
      const method = 'DELETE';
      const url = `/panel/users/${userId}`;
      const request = {
        method,
        url,
        success(response) {
          if (response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  // create

  const usersCreatePageSelector = '.users-create';
  const usersCreateForm = $(usersCreatePageSelector).find('#users-create-form');
  formValidator(usersCreateForm);

  // Datepicker
  usersCreateForm.find('.valid-until-input').datepicker({
    todayBtn: true,
    todayHighlight: true,
    autoclose: true,
    format: 'dd/mm/yyyy',
    language: 'pt-BR',
  });

  // Ativa/desativa text input de validade quando indeterminado está marcado.
  usersCreateForm.find('.js-indefinite-period-input').on('change', function() {
    const form = usersCreateForm;
    const input = $(this).is(':checked');
    if (input) {
      form.find('.valid-until-input').prop('disabled', true);
      form.find('.valid-until-input').prop('required', false);
      form.find('.valid-until-input').val('');
    } else {
      form.find('.valid-until-input').prop('disabled', false);
      form.find('.valid-until-input').prop('required', true);
      form.find('.valid-until-input').val('');
    }
  });

  // show

  // edit

  const usersEditPageSelector = '.users-edit';
  const usersEditForm = $(usersEditPageSelector).find('#users-edit-form');
  formValidator(usersEditForm);

  // Design

  const designPageSelector = '.design';
  const designForm = $(designPageSelector).find('#design-form');
  formValidator(designForm);

  // Remove Logo...
  $(designPageSelector).find('.js-btn-logo-delete').on('click',
    function(event) {
      event.preventDefault();
      const method = 'DELETE';
      const url = '/panel/design/logo-delete';
      const request = {
        method,
        url,
        success(response) {
          if (response.invalidRequest) {
            messageBox('Erro', response.invalidRequest, 'error', true, false,
              false);
          } else if (response.successResponse) {
            window.location.reload();
          }
        },
        error(response) {
          errorMessageBox(response.status);
        },
      };
      deletionDialogBox(request);
    });

  // Domain

  // const domainPageSelector = '.domain';
  // const domainForm = $(domainPageSelector).find('#domain-form');
  // formValidator(domainForm);

  // Profile

  const profilePageSelector = '.profile';
  const profileForm = $(profilePageSelector).find('#profile-form');
  formValidator(profileForm);

  // Preenche os campos através do CEP.
  profileForm.find('.zip-code-input').on('keyup', function() {
    const input = $(this);
    const form = profileForm;
    const zipCode = input.val().replace(/[-. ]/gi, '');

    if (isValidCep(zipCode)) {
      // Sendo válido no cliente, desativa o input CEP.
      input.prop('disabled', true);
      // bloqueia a UI.
      $.blockUI();

      // Roda a requisição ajax com timeout.
      setTimeout(function() {
        $.ajax({
          dataType: 'jsonp',
          url: `https://viacep.com.br/ws/${zipCode}/json/`,
        }).done(function(response) {
          if (!response.erro) {
            // Preenche os elementos com os dados da response.

            // Logradouro.
            form.find('.public-area-input').val(response.logradouro);

            // Número.
            form.find('.number-input').trigger('focus');

            // Bairro.
            form.find('.neighborhood-input').val(response.bairro);

            // Cidade.
            form.find('.city-input').val(response.localidade);

            // UF.
            form.find('.fu-input').val(response.uf);

            // Reativa CEP.
            input.prop('disabled', false);

            // Reativa a UI.
            $.unblockUI();
          } else {
            // Em caso de falha nos dados enviados.

            // Reativa a UI.
            $.unblockUI();

            // Responde ao usuário.
            const message = 'CEP não encontrado, por favor preencha manualmente.';
            messageBox('Erro', message, 'error', true, false, false);

            // Reativa CEP.
            input.prop('disabled', false);
          }
        }).fail(function() {
          // Em caso de falha na request.

          // Reativa a UI.
          $.unblockUI();

          // Responde ao usuário.
          const message = `Não conseguimos processar sua solicitação.
            Preencha os campos manualmente.`;
          messageBox('Erro', message, 'error', true, false, false);

          // Reativa CEP.
          input.prop('disabled', false);
        });
      }, 2000);
    }
  });

  // Mostra/Oculta itens de PF/PJ conforme seleção de radio.
  profileForm.find('[name=type]').on('click', function() {
    const form = profileForm;
    const inputValue = $(this).val();
    const hashClass = form.hasClass('was-validated');

    // Remove a classe de validação do BS4, caso possua.
    if (hashClass) {
      form.removeClass('was-validated');
    }

    if (inputValue === 'N') {
      // Desativa itens de PJ.
      form.find('.le-type-container').addClass('d-none');

      // CNPJ
      form.find('.ctr-input').prop('required', false).val('');

      // Razão Social.
      form.find('.company-name-input').prop('required', false).val('');

      // Nome Fantasia.
      form.find('.trading-name-input').val('');

      // Mostra itens de PF
      form.find('.np-type-container').removeClass('d-none');

      // CPF
      form.find('.itin-input').prop('required', true).val('');

      // Nome
      form.find('.first-name-input').prop('required', true).val('');

      // Sobrenome
      form.find('.last-name-input').prop('required', true).val('');
    } else {
      // Desativa itens de PF.
      form.find('.np-type-container').addClass('d-none');

      // CPF
      form.find('.itin-input').prop('required', false).val('');

      // Nome
      form.find('.first-name-input').prop('required', false).val('');

      // Sobrenome
      form.find('.last-name-input').prop('required', false).val('');

      // Reativa itens de PJ.
      form.find('.le-type-container').removeClass('d-none');

      // CNPJ
      form.find('.ctr-input').prop('required', true).val('');

      // Razão Social.
      form.find('.company-name-input').prop('required', true).val('');

      // Nome Fantasia.
      form.find('.trading-name-input').val('');
    }
  });

  // Mascaras de Input.

  // CNPJ.
  profileForm.find('.ctr-input').mask('00.000.000/0000-00', {
    reverse: true,
  });

  // CPF.
  profileForm.find('.itin-input').mask('000.000.000-00', {
    reverse: true,
  });

  // CEP.
  profileForm.find('.zip-code-input').mask('00.000-000');

  // Telefone Fixo.
  profileForm.find('.phone-input').mask('(99) 99999999');

  // Telefone Celular.
  profileForm.find('.mobile-phone-input').mask('(99) 999999999');

  // Whatsapp.
  profileForm.find('.whatsapp-input').mask('(99) 999999999');

  // Setting

  const settingPageSelector = '.setting';
  const settingForm = $(settingPageSelector).find('#setting-form');
  formValidator(settingForm);

  // Account

  const accountPageSelector = '.account';
  const accountForm = $(accountPageSelector).find('#account-form');
  formValidator(accountForm);

  // Account - Password Change

  const accountPasswordChangePageSelector = '.account-password-change';
  const accountPasswordChangeForm = $(accountPasswordChangePageSelector).
    find('#account-password-change-form');
  formValidator(accountPasswordChangeForm);

  // Account - E-mail Change

  const accountEmailChangePageSelector = '.account-email-change';
  const accountEmailChangeForm = $(accountEmailChangePageSelector).
    find('#account-email-change-form');
  formValidator(accountEmailChangeForm);

  // Forms

  // index

  // const formsPageSelector = '.forms';

  // Delete button em index

  // delete

  // $(formsPageSelector).find('.js-btn-forms-destroy').on('click',
  //   function (event) {
  //     event.preventDefault();
  //     const formId = $(this).attr('data-form-id');
  //     const method = 'DELETE';
  //     const url = `/panel/forms/${formId}`;
  //     const request = {
  //       method,
  //       url,
  //       success(response) {
  //         console.log(`Deletou o formulário id ${formId}`);
  //         // console.log(response);
  //       },
  //       error(response) {
  //         console.log(response);
  //       },
  //     };
  //     deletionDialogBox(request);
  //   });

  // create

  // const formsCreatePageSelector = '.forms-create';
  // const formsCreateForm = $(formsCreatePageSelector).find('#forms-create-form');
  // formValidator(formsCreateForm);

  // edit

  // const formsEditPageSelector = '.forms-edit';
  // const formsEditForm = $(formsEditPageSelector).find('#forms-edit-form');
  // formValidator(formsEditForm);

  // Form Item

  // index

  // const formsItemsPageSelector = '.forms-items';

  // Delete button em index

  // delete

  // $(formsItemsPageSelector).find('.js-btn-forms-items-destroy').on('click',
  //   function (event) {
  //     event.preventDefault();
  //     const formId = $(this).attr('data-form-id');
  //     const itemId = $(this).attr('data-item-id');
  //     const method = 'DELETE';
  //     const url = `/panel/forms/${formId}/items/${itemId}`;
  //     const request = {
  //       method,
  //       url,
  //       success(response) {
  //         console.log(`Deletou o item id ${itemId} do formulário id ${formId}`);
  //         // console.log(response);
  //       },
  //       error(response) {
  //         console.log(response);
  //       },
  //     };
  //     deletionDialogBox(request);
  //   });

  // create

  // const formsItemsCreatePageSelector = '.forms-items-create';
  // const formsItemsCreateForm = $(formsItemsCreatePageSelector).find('#forms-items-create-form');
  // formValidator(formsItemsCreateForm);

  // edit

  // const formsItemsEditPageSelector = '.forms-items-edit';
  // const formsItemsEditForm = $(formsItemsEditPageSelector).find('#forms-items-edit-form');
  // formValidator(formsItemsEditForm);

  // File

  // index

  // const filesPageSelector = '.files';

  // Delete button em index

  // delete

  // $(filesPageSelector).find('.js-btn-files-destroy').on('click',
  //   function (event) {
  //     event.preventDefault();
  //     const fileId = $(this).attr('data-file-id');
  //     const method = 'DELETE';
  //     const url = `/panel/files/${fileId}`;
  //     const request = {
  //       method,
  //       url,
  //       success(response) {
  //         console.log(`Deletou o arquivo id ${fileId}`);
  //         // console.log(response);
  //       },
  //       error(response) {
  //         console.log(response);
  //       },
  //     };
  //     deletionDialogBox(request);
  //   });

  // create

  // const filesCreatePageSelector = '.files-create';
  // const filesCreateForm = $(filesCreatePageSelector).find('#files-create-form');
  // formValidator(filesCreateForm);

  // Page

  // index

  // const pagesPageSelector = '.pages';

  // delete em index

  // delete

  // $(pagesPageSelector).find('.js-btn-pages-destroy').on('click',
  //   function (event) {
  //     event.preventDefault();
  //     const pageId = $(this).attr('data-page-id');
  //     const method = 'DELETE';
  //     const url = `/panel/pages/${pageId}`;
  //     const request = {
  //       method,
  //       url,
  //       success(response) {
  //         console.log(`Deletou a página id ${pageId}`);
  //         // console.log(response);
  //       },
  //       error(response) {
  //         console.log(response);
  //       },
  //     };
  //     deletionDialogBox(request);
  //   });

  // create

  // const pagesCreatePageSelector = '.pages-create';
  // const pagesCreateForm = $(pagesCreatePageSelector).find('#pages-create-form');
  // formValidator(pagesCreateForm);
  //
  // // Editor
  // pagesCreateForm.find('#content-input').trumbowyg({
  //   lang: 'pt_br',
  //   resetCss: true,
  //   removeformatPasted: true,
  //   tagsToRemove: ['script', 'link'],
  //   imageWidthModalEdit: true,
  // });

  // show

  // edit

  // const pagesEditPageSelector = '.pages-edit';
  // const pagesEditForm = $(pagesEditPageSelector).find('#pages-edit-form');
  // formValidator(pagesEditForm);
  //
  // // Editor
  // pagesEditForm.find('#content-input').trumbowyg({
  //   lang: 'pt_br',
  //   resetCss: true,
  //   removeformatPasted: true,
  //   tagsToRemove: ['script', 'link'],
  //   imageWidthModalEdit: true,
  // });

  // User

  // Page

  // index

  // const pagesPageSelector = '.pages';

  // delete em index

  // delete

  // $(pagesPageSelector).find('.js-btn-pages-destroy').on('click',
  //   function (event) {
  //     event.preventDefault();
  //     const pageId = $(this).attr('data-page-id');
  //     const method = 'DELETE';
  //     const url = `/panel/pages/${pageId}`;
  //     const request = {
  //       method,
  //       url,
  //       success(response) {
  //         console.log(`Deletou a página id ${pageId}`);
  //         // console.log(response);
  //       },
  //       error(response) {
  //         console.log(response);
  //       },
  //     };
  //     deletionDialogBox(request);
  //   });

  // create

  // const pagesCreatePageSelector = '.pages-create';
  // const pagesCreateForm = $(pagesCreatePageSelector).find('#pages-create-form');
  // formValidator(pagesCreateForm);

  // show

  // edit

  // const pagesEditPageSelector = '.pages-edit';
  // const pagesEditForm = $(pagesEditPageSelector).find('#pages-edit-form');
  // formValidator(pagesEditForm);

  // jQuery Callback

  // MSG em breve para links de páginas ainda não construídas.
  $('.js-btn-building').on('click', function(e) {
    e.preventDefault();
    const message = 'Recurso em breve disponível, por favor aguarde.';
    // title, text, icon, confirm, cancel, reload
    messageBox('Em breve', message, 'info', true, false, false);
  });
});
