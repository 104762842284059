/**
 * Realiza a validação e anima o botão na submissão de formulários.
 *
 * @param targetForm
 */
function formValidator(targetForm = null) {
  if (targetForm !== null) {
    const form = $(targetForm);
    form.on('submit', function (e) {
      if (!form[0].checkValidity()) {
        e.preventDefault();
        form.addClass('was-validated');
      } else {
        form.find('.submit-container > .js-btn-submit').prop('disabled', true).addClass('d-none');
        form.find('.submit-container > .loading').removeClass('d-none');
      }
    });
  }
}

module.exports = formValidator;
