const swal = require('sweetalert');

/**
 * Caixa de mensagens.
 *
 * @param title string
 * @param text string
 * @param icon string
 * @param confirm bool
 * @param cancel bool
 * @param reload bool
 */
function messageBox(title, text, icon, confirm, cancel, reload) {
  swal({
    title,
    text,
    icon,
    buttons: {
      confirm: {
        text: 'Ok',
        value: true,
        visible: confirm,
        className: 'btn btn-success text-white',
        closeModal: true,
      },
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: cancel,
        className: 'btn btn-danger text-white',
        closeModal: true,
      },
    },
  }).then(() => {
    if (reload) {
      window.location.reload();
    }
  });
}

module.exports = messageBox;
