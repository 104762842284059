window.$ = window.jQuery = require('jquery');
const swal = require('sweetalert');

/**
 * Caixa de diálogo para exclusão.
 *
 * @param ajaxRequisitionSettings
 */
function deletionDialogBox(ajaxRequisitionSettings) {
  swal({
    title: 'Confirme a Exclusão',
    text: 'Você realmente deseja excluir ?',
    icon: 'warning',
    buttons: {
      confirm: {
        text: 'Excluir',
        value: true,
        visible: true,
        className: 'btn btn-danger',
        closeModal: true,
      },
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        className: 'btn btn-primary',
        closeModal: true,
      },
    },
  }).then((confirm) => {
    if (confirm) {
      $.ajax(ajaxRequisitionSettings);
    }
  });
}

module.exports = deletionDialogBox;
